import React from "react";

import { ContactForm } from "components/ContactForm";
import { ContactBackground } from "components/ContactBackgroung";
import { ContactInformation } from "components/view/ContactInformation";
import { HireUS } from "components/view/HireUS";
import { ViewHeader } from "components/view/ViewHeader";
import { Container, Layout } from "components/layout";
import { SEO } from "components/common/SEO";

export default function ContactPage() {
  return (
    <Layout fullscreen dark>
      <SEO title="Contact" />
      <ViewHeader dark />
      <Container flex>
        <ContactBackground>
          <ContactInformation>
            <ContactForm />
          </ContactInformation>
        </ContactBackground>
      </Container>
      <HireUS forceDark />
    </Layout>
  );
}
