import React, { useCallback, useMemo } from "react";
import { useContact } from "hooks/api";
import {
  FormButton,
  Form,
  FormTextInput,
  FormFooter,
} from "components/common/Form";

const REQUIRED_STRING = "This field may not be blank.";

export function ContactForm() {
  const contact = useContact();
  const isSent = useMemo(() => contact.data?.success || false, [contact]);
  const handleSubmit = useCallback(
    async (data: FormData) => {
      contact.cleanErrors();
      return contact.process(data);
    },
    [contact]
  );

  return (
    <>
      <h2>Get in touch!</h2>
      <Form centered onSubmit={handleSubmit} maxWidth="400px">
        <FormTextInput
          disabled={isSent}
          field="name"
          label="Name"
          error={contact.errors?.["name"]?.[0]}
          formatError={(error) => {
            switch (error) {
              case REQUIRED_STRING:
                return "Enter your name.";
              default:
                return error;
            }
          }}
        />
        <FormTextInput
          disabled={isSent}
          type="email"
          field="email"
          label="Email"
          error={contact.errors?.["email"]?.[0]}
          formatError={(error) => {
            switch (error) {
              case REQUIRED_STRING:
                return "Enter your email address.";
              default:
                return error;
            }
          }}
        />
        <FormTextInput
          disabled={isSent}
          type="textarea"
          field="message"
          label="Your message"
          error={contact.errors?.["message"]?.[0]}
          formatError={(error) => {
            switch (error) {
              case REQUIRED_STRING:
                return "Enter your message.";
              default:
                return error;
            }
          }}
        />
        <FormFooter>
          <FormButton disabled={isSent}>Submit</FormButton>
          {isSent && <div>Message Sent</div>}
        </FormFooter>
      </Form>
    </>
  );
}
