import React from "react";
import backgroundLand from "images/background-land.svg";
import leniLogoImg from "images/leni-logo.svg";
import telescopeImg from "images/telescope.svg";
import backgroundSky from "images/background-sky.svg";
import { className } from "utils/className";
import {
  contactBackground,
  sky,
  land,
  contactImagesContainer,
  leniLogo,
  telescope,
  wrapper,
  childrenStyle,
} from "./ContactBackground.module.scss";

interface ContactBackgroundProps {
  children?: React.ReactNode;
}
export function ContactBackground(props: ContactBackgroundProps) {
  return (
    <div className={className(wrapper)}>
      <div className={className(contactBackground, sky)}>
        <img src={backgroundSky} alt="sky background" />
      </div>
      <div className={className(childrenStyle)}> {props.children} </div>
      <div className={className(contactImagesContainer)}>
        <img
          src={leniLogoImg}
          alt="leniolabs logo"
          className={className(leniLogo)}
        />
        <img
          src={telescopeImg}
          alt="telescope"
          className={className(telescope)}
        />
      </div>
      <div className={className(contactBackground, land)}>
        <img src={backgroundLand} alt="land background" />
      </div>
    </div>
  );
}
